// @flow strict
import React from 'react';
import { getContactHref } from '../../../utils';
import styles from './Author.module.scss';
import { useSiteMetadata } from '../../../hooks';
import { Link, withPrefix } from 'gatsby';

const Author = ({top}) => {
  const { author } = useSiteMetadata();

  return (
    <div className={`${styles['author']} ${top && styles['author-top']}`}>
      <div className={styles['author__photo']}>
        <Link to={getContactHref('twitter', author.contacts.twitter)}>
          <img
            src={withPrefix(author.photo)}
            width="75"
            height="75"
            alt={author.name}
          />
        </Link>
      </div>
      <div className={styles['author__bio']}>
        <strong>{top && 'By'} {author.name}</strong>: {author.bio}
        <span className={styles['author__bio-twitter']}>
          <a
            href={getContactHref('twitter', author.contacts.twitter)}
            rel="noopener noreferrer"
            target="_blank"
          >
          <strong>Follow me</strong> on Twitter
        </a>
        </span>
      </div>
    </div>
  );
};

export default Author;
